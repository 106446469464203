define("discourse/plugins/discourse-events/discourse/models/provider", ["exports", "@ember/array", "@ember/object/computed", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/singleton", "discourse/models/rest", "discourse-common/utils/decorators"], function (_exports, _array, _computed, _service, _ajax, _ajaxError, _singleton, _rest, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TOKEN_TYPES = _exports.OAUTH2_TYPES = _exports.NO_AUTH_TYPES = void 0;
  const TOKEN_TYPES = _exports.TOKEN_TYPES = ["eventbrite", "humanitix", "eventzilla"];
  const NO_AUTH_TYPES = _exports.NO_AUTH_TYPES = ["icalendar"];
  const OAUTH2_TYPES = _exports.OAUTH2_TYPES = ["meetup", "outlook", "google"];
  const Provider = dt7948.c(class Provider extends _rest.default {
    static #_ = (() => dt7948.g(this.prototype, "subscription", [(0, _service.service)("events-subscription")]))();
    #subscription = (() => (dt7948.i(this, "subscription"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "notInSubscription", [(0, _computed.not)("inSubscription")]))();
    #notInSubscription = (() => (dt7948.i(this, "notInSubscription"), void 0))();
    stored(providerId) {
      return providerId && providerId !== "new";
    }
    static #_3 = (() => dt7948.n(this.prototype, "stored", [(0, _decorators.default)("id")]))();
    status(hasCredentials, providerStored, providerAuthenticated, inSubscription) {
      if (!inSubscription) {
        return "not_in_subscription";
      }
      if (hasCredentials) {
        return providerAuthenticated ? "ready" : "not_authenticated";
      } else {
        return providerStored ? "ready" : "not_ready";
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "status", [(0, _decorators.default)("hasCredentials", "stored", "authenticated", "inSubscription")]))();
    hasCredentials(providerType) {
      return providerType && !NO_AUTH_TYPES.includes(providerType);
    }
    static #_5 = (() => dt7948.n(this.prototype, "hasCredentials", [(0, _decorators.default)("provider_type")]))();
    inSubscription(subscriptionProviders, providerType) {
      return this.subscription.supportsFeatureValue("provider", "provider_type", providerType);
    }
    static #_6 = (() => dt7948.n(this.prototype, "inSubscription", [(0, _decorators.default)("subscription.features.provider", "provider_type")]))();
  }, [_singleton.default]);
  var _default = _exports.default = Provider;
  Provider.reopenClass({
    all() {
      return (0, _ajax.ajax)("/admin/plugins/events/provider").catch(_ajaxError.popupAjaxError);
    },
    update(provider) {
      return (0, _ajax.ajax)(`/admin/plugins/events/provider/${provider.id}`, {
        type: "PUT",
        data: {
          provider
        }
      }).catch(_ajaxError.popupAjaxError);
    },
    destroy(provider) {
      return (0, _ajax.ajax)(`/admin/plugins/events/provider/${provider.id}`, {
        type: "DELETE"
      }).catch(_ajaxError.popupAjaxError);
    },
    toArray(store, providers) {
      return (0, _array.A)(providers.map(provider => {
        return store.createRecord("provider", provider);
      }));
    }
  });
});